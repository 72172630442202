/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";

const items = [
  // {
  //   src: require("assets/img/theme/DCXIdeathon_Mahesh_Bhusanoor_Capgemini.png"),
  //   altText: "Mahesh Bhusanoor",
  //   caption: "Mahesh Bhusanoor",
  //   header: "Mahesh Bhusanoor",
  // },
  {
    src: require("assets/img/theme/Mahesh bhusanoor Coimbture Workshop.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/theme/Mahesh Bhusanoor Kerala workshop.jpg"),
    altText: "Mahesh Bhusanoor",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/theme/Mahesh Bhusanoor Loyala Institute Workshop.jpg"),
    altText: "Mahesh Bhusanoor",
    caption: "",
    header: "",
  },
  // {
  //   src: require("assets/img/theme/IIT_Bombay.jpg"),
  //   altText: "Mahesh Bhusanoor",
  //   caption: "",
  //   header: ""
  // },
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light">
                  {"<maheshvirus>"}
                </h1>
                <p className="lead text-white mt-4">
                  Lover of Mobile Apps, Web Apps, Blogger, Speaker, Trainer,
                  Developer, and Researcher. Educated more than 5000+ students
                  all over India. Including IITs and NITs by conducting
                  technical workshops.
                </p>
                {/* <Button
                  className="btn-white mt-4"
                  color="default"
                  href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                >
                  See all components
                </Button> */}
                <Button
                  className="btn-white mt-4"
                  color="default"
                  id="tooltip62844899"
                  size="sm"
                >
                  Mobile/Web Apps
                </Button>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  id="tooltip628448991"
                  size="sm"
                >
                  Augmented/Virtual Reality
                </Button>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  id="tooltip628448992"
                  size="sm"
                >
                  Robotics
                </Button>
                <UncontrolledPopover
                  trigger="focus"
                  placement="top"
                  target="tooltip62844899"
                >
                  <PopoverHeader>Mobile/Web Apps Workshops</PopoverHeader>
                  <PopoverBody>
                    IIT Mumbai
                    <br />
                    SVR Engineering College Nandyala(AP)
                    <br />
                    Siddharth Polytechnic Jath
                    <br />
                    IIT Jodhpur Rajasthan
                    <br />
                    SVNIT, Surat
                    <br />
                    {/* IIT Mumbai<br/> */}
                  </PopoverBody>
                </UncontrolledPopover>
                <UncontrolledPopover
                  trigger="focus"
                  placement="top"
                  target="tooltip628448991"
                >
                  <PopoverHeader>Augmented/Virtual Reality</PopoverHeader>
                  <PopoverBody>
                    CMR Technical Campus Hyderabad
                    <br />
                    KL University Guntur
                    <br />
                    IIT Jodhpur Rajasthan
                    <br />
                    {/* IIT Mumbai<br/> */}
                  </PopoverBody>
                </UncontrolledPopover>
                <UncontrolledPopover
                  trigger="focus"
                  placement="top"
                  target="tooltip628448992"
                >
                  <PopoverHeader>Robotics Workshops</PopoverHeader>
                  <PopoverBody>
                    Government Engineering College, Barton Hill Trivandrum
                    <br />
                    IIT Mumbai
                    <br />
                    Loyola Institute of Technology Kanyakumari
                    <br />
                    (SKCET) Coimbatore
                    <br />
                    MKCE Karur
                    <br />
                    {/* IIT Mumbai<br/> */}
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Carousel;
