import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import "../../assets/css/smoke.css";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                {/* <img
                  alt="Digital Chokra"
                  style={{ width: "100%" }}
                  src={require("assets/images/Digital Chokra Home Background.png")}
                /> */}
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-4 text-white">
                        Digital Chokra{" "}
                        <span>Empowering Your Digital Transformation</span>
                      </h1>
                      <p className="lead text-white">
                        Our vision is to be the preferred partner for businesses
                        looking to digitalize their customer experience. We aim
                        to empower our clients with innovative and high-quality
                        IT solutions that will help them stay ahead of the curve
                        in the rapidly evolving digital landscape.
                      </p>

                      {/* <div className="card-profile-stats d-flex justify-content-center">
                        <img
                          alt="..."
                          style={{ width: "80%", height: "100%" }}
                          src={require("assets/gif/104775-sales-sky-rocket.gif")}
                        />
                      </div> */}

                      <div className="btn-wrapper">
                        <Button className="btn-icon mb-3 mb-sm-0">
                          <span className="btn-inner--text">200+ Clients</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3  mb-sm-0 ml-1"
                          color="default"
                        >
                          <span className="btn-inner--text">15+ Countries</span>
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="btn-wrapper">
                        <Button
                          className=" mb-sm-0"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="card-profile-stats d-flex justify-content-center">
                            <img
                              alt="..."
                              style={{
                                width: "100%",
                                height: "80%",
                                backgroundColor: "white",
                              }}
                              src={require("assets/gif/134153-c2-digital.gif")}
                            />
                          </div>{" "}
                          Your Digital Partner
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                            Customer-Centric Approach
                          </h6>
                          <p className="description mt-3">
                            We believe in putting the customer first and strive
                            to deliver solutions that meet their unique needs
                            and exceed their expectations.
                          </p>
                          {/* <div>
                            <Badge color="primary" pill className="mr-1">
                              design
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              system
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              creative
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                            Quality and Reliability
                          </h6>
                          <p className="description mt-3">
                            We are committed to delivering high-quality
                            solutions and providing exceptional customer
                            support, ensuring our clients' success.
                          </p>
                          {/* <div>
                            <Badge color="success" pill className="mr-1">
                              business
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              vision
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              success
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          {/* <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div> */}
                          <h6 className="text-success text-uppercase">
                            Innovation & Collaboration
                          </h6>
                          <p className="description mt-3">
                            We embrace new technologies and methodologies to
                            drive digital transformation and continuously
                            improve our services.
                          </p>
                          {/* <div>
                            <Badge color="warning" pill className="mr-1">
                              marketing
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              product
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              launch
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-10 hideShow">
            <Container>
              {/* <Card className=" shadow-lg border-0"> */}
              <div className="p-5 ">
                <Row className="align-items-center ">
                  <Col lg="5">
                    <div className="btn-wrapper">
                      <h1 className="smoke-h1 textSize">
                        <span>D</span>
                        <span>i</span>
                        <span>g</span>
                        <span>i</span>
                        <span>t</span>
                        <span>a</span>
                        <span>l</span>
                      </h1>
                    </div>
                  </Col>
                  <Col className="ml-lg-auto" lg="2">
                    <div className="btn-wrapper">
                      <h1 className="smoke-h1 textSize">
                        <span>
                          {" "}
                          <img
                            className="logoStyle"
                            alt="Logo"
                            src={require("assets/images/Digital Chokra-logos_black.png")}
                          />
                        </span>
                      </h1>
                    </div>
                  </Col>
                  <Col className="ml-lg-auto" lg="5">
                    <div className="btn-wrapper">
                      <h1 className="smoke-h1 textSize">
                        <span>C</span>
                        <span>h</span>
                        <span>o</span>
                        <span>k</span>
                        <span>r</span>
                        <span>a</span>
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center ">
                  <Col className="ml-lg-auto" lg="12">
                    <div className="btn-wrapper">
                      <h1
                        className="smoke-h1 textSize"
                        style={{ fontSize: " 15px", fontWeight: 600 }}
                      >
                        <span>Empowering</span>
                        <span>Your</span>
                        <span>Digital </span>
                        <span>Transformation</span>
                      </h1>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* </Card> */}
            </Container>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/gif/104775-sales-sky-rocket.gif")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-badge" />
                    </div>
                    <h3>About Digital Chokra</h3>
                    <p>
                      Digital Chokra is an IT solutions and consulting company
                      with a focus on providing end-to-end services to customers
                      worldwide. <br />
                      Our expertise covers a wide range of areas including
                      Security, Payment Integration,Mobile App Development, Web
                      App Development, Digital Marketing, Cloud Solutions,
                      Blockchain, CRM, AI & ML, and Resource Management. <br />
                      With over 200+ satisfied clients globally, we have
                      established ourselves as a trusted and reliable provider
                      of cutting-edge IT solutions.
                      <br />
                      Our vision is to be the preferred partner for businesses
                      looking to digitalize their customer experience. <br />
                      We aim to empower our clients with innovative and
                      high-quality IT solutions that will help them stay ahead
                      of the curve in the rapidly evolving digital landscape.
                    </p>
                    {/* <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Carefully crafted components
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Amazing page examples</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Super friendly support team
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Application Development"
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Mobile_Web_Developemnt.gif")}
                          />
                        </div>{" "}
                        Software Development
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Blockchain & Security"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Blockchain.gif")}
                          />
                        </div>{" "}
                        Blockchain & Security
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Cloud Solutions"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Cloud_Services.gif")}
                          />
                        </div>{" "}
                        Cloud Solutions
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-money-coins" />
                    </div>
                    <h3>Our Services</h3>
                    {/* <p className="lead">
                      Our team of experts is dedicated to helping businesses
                      digitalize their customer experience and stay ahead of the
                      curve in the rapidly evolving digital landscape.
                    </p> */}
                    <p>
                      Our team of experts is dedicated to helping businesses
                      digitalize their customer experience and stay ahead of the
                      curve in the rapidly evolving digital landscape.
                    </p>
                    {/* <p>
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go.
                    </p>
                    <a
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      A beautiful UI Kit for impactful websites
                    </a> */}
                    {/* <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="..."
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/134153-c2-digital.gif")}
                          />
                        </div>{" "}
                        Hello
                      </Button>
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row className="row-grid align-items-center">
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Digital Payments"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Payments.gif")}
                          />
                        </div>{" "}
                        Digital Payments
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Digital Marketing"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/99954-digital-marketing-services.gif")}
                          />
                        </div>{" "}
                        Digital Marketing
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="Resource Management"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Resource_Management.gif")}
                          />
                        </div>{" "}
                        Resource Management
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-lift--hover shadow border-0">
                    <div className="btn-wrapper">
                      <Button
                        className=" mb-sm-0"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="card-profile-stats d-flex justify-content-center">
                          <img
                            alt="AI & ML"
                            style={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "white",
                            }}
                            src={require("assets/gif/Digital_Chokra_Machine_Learning.gif")}
                          />
                        </div>{" "}
                        AI & ML
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-0 bg-gradient-success">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    {/* <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/gif/91237-working.gif")}
                    /> */}
                    <Card className="card-lift--hover shadow shadow-lg--hover mt-5">
                      <CardBody>
                        <div className="d-flex px-3">
                          {/* <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div> */}
                          <div className="pl-4">
                            <h5 className="title text-success">Custellor</h5>
                            <p>
                              Custellor is a comprehensive CRM solution that can
                              help you streamline your sales, marketing, and
                              customer support processes. With its user-friendly
                              interface and powerful features, Custellor enables
                              you to centralize all your customer data, automate
                              your workflows, and provide a seamless experience
                              to your customers.
                            </p>
                            <a
                              className="text-success"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Know more
                            </a>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-shop text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Our Products</h4>
                      <p className="text-white">
                        We are a leading provider of cutting-edge IT solutions,
                        and our suite of products is designed to help businesses
                        digitalize their customer experience and stay ahead of
                        the curve in the rapidly evolving digital landscape.
                      </p>
                    </div>
                  </div>
                  <Card className="card-lift--hover shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3 ">
                        {/* <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div> */}
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Digital e-wallet
                          </h5>
                          <p>
                            Digital e-wallet is a secure and convenient digital
                            payment solution. It enables businesses to easily
                            manage and track their financial transactions,
                            eliminating the need for physical cash or checks.
                            With Digital e-wallet, businesses can improve their
                            financial management processes and reduce the risk
                            of fraud and theft.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Know more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-lift--hover shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        {/* <div>
                           <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div> */}
                        <div className="pl-4">
                          <h5 className="title text-success">BotMate</h5>
                          <p>
                            BotMate is a highly advanced and intuitive
                            AI-powered chatbot solution that can help you
                            enhance your customer experience by providing quick,
                            accurate and personalized support 24/7. With its
                            advanced natural language processing capabilities
                            and machine learning algorithms, BotMate can handle
                            a wide range of customer queries with ease, freeing
                            up your support team to focus on more complex tasks.
                          </p>
                          <a
                            href="#pablo"
                            className="text-success"
                            onClick={(e) => e.preventDefault()}
                          >
                            Know more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">The amazing Team</h2>
                  <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Ryan Tompson</span>
                        <small className="h6 text-muted">Web Developer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Romina Hadid</span>
                        <small className="h6 text-muted">
                          Marketing Strategist
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Alexander Smith</span>
                        <small className="h6 text-muted">UI/UX Designer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">John Doe</span>
                        <small className="h6 text-muted">Founder and CEO</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
          <section className="section section-lg pt-10">
            <Container>
              <Card className="bg-gradient-success shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="6">
                      <h3 className="text-white">
                        We help to empower your digital transformation journey!!
                      </h3>
                      <p className="lead text-white mt-3">
                        At Digital Chokra, we are dedicated to providing
                        high-quality solutions and exceptional customer support
                        to help our clients achieve their business goals. Our
                        products are designed to be flexible, scalable, and easy
                        to use, and we are committed to continuously improving
                        and updating our offerings to meet the evolving needs of
                        our clients.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="6">
                      <div className="btn-wrapper">
                        <Button
                          className="card-lift--hover  mb-sm-0"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="card-profile-stats d-flex justify-content-center">
                            <img
                              alt="..."
                              style={{
                                width: "100%",
                                height: "80%",
                                backgroundColor: "white",
                              }}
                              src={require("assets/gif/digital_transformation.gif")}
                            />
                          </div>{" "}
                          Empowering Digital Transformation
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-success">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="12">
                  <h2 className="display-3 text-white">Contact us</h2>
                  <p className="lead text-white">
                    Thank you for visiting Digital Chokra! We are always here to
                    assist you with any questions or concerns.
                    <br />
                    If you need more information on our services or products,
                    please reach out to us using the details below.
                  </p>
                </Col>
              </Row>
              {/* <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Building tools</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Grow your market</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Launch time</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
              </Row> */}
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondory shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Get in touch with us!!</h4>
                      <p className="mt-0">
                        We value your feedback and appreciate your interest in
                        Digital Chokra. Thank you for considering us as your IT
                        solutions and consulting partner.
                      </p>
                      <p className="mb-1">
                        Email :{" "}
                        <a href="mailto: info@digitalchokra.com">
                          info@digitalchokra.com
                        </a>
                        <br />
                        Location : Office 2192, International Business Tower,
                        Business Bay, Dubai, UAE
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          {/* <Download /> */}
        </main>
        {/* <CardsFooter /> */}
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
