/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Badge,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Progress,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Carousel from "views/IndexSections/Carousel";
const items = [
  {
    src: require("assets/img/theme/DCXIdeathon_Mahesh_Bhusanoor_Capgemini.png"),
    altText: "Mahesh Bhusanoor",
    caption: "Mahesh Bhusanoor",
    header: "Mahesh Bhusanoor",
  },
  {
    src: require("assets/img/theme/Mahesh bhusanoor Coimbture Workshop.jpg"),
    altText: "Mahesh Bhusanoor",
    caption: "Mahesh Bhusanoor",
    header: "Mahesh Bhusanoor",
  },
  {
    // src: require("assets/img/theme/Mahesh Bhusanoor Kerala workshop.jpg"),
    altText: "Mahesh Bhusanoor",
    caption: "",
    header: "",
  },
  {
    // src: require("assets/img/theme/Mahesh Bhusanoor Loyala Institute Workshop.jpg"),
    altText: "Mahesh Bhusanoor",
    caption: "Mahesh Bhusanoor",
    header: "Mahesh Bhusanoor",
  },
  // {
  //   src: require("assets/img/theme/IIT_Bombay.jpg"),
  //   altText: "Mahesh Bhusanoor",
  //   caption: "",
  //   header: ""
  // },
];
const emailValidation = (value) => {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
};
class Profile extends React.Component {
  state = {};
  // emailValidation = (value) => {
  //   return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);
  // }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.state.experience = this.calculate_age("06/02/2014");
    this.state.message = "";
    this.state.name = "";
    this.state.email = "";
  }

  calculate_age = (dob) => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    return Math.abs(age.getUTCFullYear() - 1970);
  };

  submitQuery = () => {
    if (this.state.name == "") {
      alert("Name should not be blank");
      return true;
    }
    if (this.state.email == "") {
      alert("Email id should not be blank");
      return true;
    }
    if (!emailValidation(this.state.email)) {
      alert("Email id is not valid");
      return true;
    }
    if (this.state.message == "") {
      alert("Message should not be blank");
      return true;
    }
    fetch(
      "https://us-central1-mahesh-virus.cloudfunctions.net/sendMail?name=" +
        this.state.name +
        "&message=" +
        this.state.message +
        "&email=" +
        this.state.email +
        ""
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // this.setState({
          //   isLoaded: true,
          //   items: result.items
          // });

          this.setState({ name: "" });
          this.setState({ message: "" });
          this.setState({ email: "" });
          alert("Thank You!!");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // this.setState({
          //   isLoaded: true,
          //   error
          // });

          this.setState({ name: "" });
          this.setState({ message: "" });
          this.setState({ email: "" });
          alert("Thank You!!");
        }
      );
  };

  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 ">
            <div className="shape shape-style-1 shape-default alpha-4">
              <img
                alt="Digital Chokra"
                style={{ width: "100%" }}
                src={require("assets/images/Digital Chokra Home Background.png")}
              />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-12">
                  <Row className="justify-content-center">
                    {/* <Col className="order-lg-2" lg="12">
                      <div className="card-profile-image floating">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/images/Digital Chokra.gif")}
                          />
                        </a>
                      </div>
                    </Col> */}
                    <Col
                      className="order-lg-4 text-lg-right align-self-lg-center"
                      lg="6"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Products
                        </Button>

                        <Button
                          className="float-right"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          services
                        </Button>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">200+</span>
                          <span className="description">Clients</span>
                        </div>
                        <div>
                          <span className="heading">15+</span>
                          <span className="description">Countries</span>
                        </div>
                        {/* <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                      </div>
                    </Col>
                    {/* <Col className="order-lg-12" lg="12">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            src={require("assets/gif/91237-working.gif")}
                          />
                        </a>
                      </div>
                    </Col> */}
                  </Row>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center ">
                      <Col lg="9">
                        <p>Empowering Your Digital Transformation</p>
                      </Col>
                    </Row>
                  </div>
                  <Row className="justify-content-center">
                    {/* <div className="order-lg-1 border-bottom text-center">
                      <Row className="justify-content-center">
                        <Col lg="9">
                          <p>
                            {this.calculate_age("06/02/2014")}+ years of
                            experience in Android, IOS, Web, Azure Cloud,
                            Docker, Kubernates, DevOps, BFF, Portal, and PWA.
                          </p>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Show more
                          </a>
                        </Col>
                      </Row>
                    </div> */}

                    <Col className="order-lg-1 " lg="6">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <img
                          alt="..."
                          style={{ width: "80%", height: "100%" }}
                          src={require("assets/gif/104775-sales-sky-rocket.gif")}
                        />
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="6">
                      <div className="card-profile-stats d-flex justify-content-center">
                        {/* <img
                          alt="..."
                          style={{ width: "80%", height: "100%" }}
                          src={require("assets/gif/91237-working.gif")}
                        /> */}
                        <p>
                          Digital Chokra is an IT solutions and consulting
                          company with a focus on providing end-to-end services
                          to customers worldwide. Our expertise covers a wide
                          range of areas including Security, Payment
                          Integration,Mobile App Development, Web App
                          Development, Digital Marketing, Cloud Solutions,
                          Blockchain, CRM, AI & ML, and Resource Management.
                          With over 200+ satisfied clients globally, we have
                          established ourselves as a trusted and reliable
                          provider of cutting-edge IT solutions.
                        </p>
                      </div>
                    </Col>
                  </Row>

                  {/* <div className="text-center mt-5">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/gif/91237-working.gif")}
                      />
                    </a>
                  </div> */}
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p>
                          {this.calculate_age("06/02/2014")}+ years of
                          experience in Android, IOS, Web, Azure Cloud, Docker,
                          Kubernates, DevOps, BFF, Portal, and PWA.
                        </p>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Show more
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        {/* <Carousel /> */}
        {/* <Carousel /> */}

        <section className="section section-lg">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i className="ni ni-books" />
                  </div>
                  <h3>About Us</h3>
                  <p>
                    Digital Chokra is one of the trusted leading
                    security-conscious company across the world. We provide one
                    stop solutions to all your security issues.Fix every single
                    vulnerability before it hurts your business and achieve your
                    compliance goals, with Digital Chokra.
                  </p>
                </div>
                {/* <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  /> */}
                <Card className="shadow shadow-lg--hover mt-5 floating">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-satisfied" />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-success">
                          WEB APP APPLICATION TESTING
                        </h5>
                        <p>
                          Find and fix every single vulnerability in your web
                          app with us.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-md-1" md="6">
                <Card className="shadow shadow-lg--hover mt-5 floating">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-satisfied" />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-success">
                          CLOUD SECURITY TESTING
                        </h5>
                        <p>
                          Uncover every loophole in your cloud infrastructure
                          with us.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="shadow shadow-lg--hover mt-5 floating">
                  <CardBody>
                    <div className="d-flex px-3">
                      <div>
                        <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                          <i className="ni ni-satisfied" />
                        </div>
                      </div>
                      <div className="pl-4">
                        <h5 className="title text-success">
                          MOBILE APPLICATION TESTING
                        </h5>
                        <p>
                          Uncover loopholes in your mobile app before hackers,
                          with us.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-light ">
            <img
              alt="Digital Chokra"
              style={{ width: "100%", height: "100%" }}
              src={require("assets/images/Digital Chokra Home Background.png")}
            />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light">Contact Info</h1>
                {/* <p className="lead text-white mt-4">
                  I have conducted 40+ Technical workshops on Engineering colleges all over India. Educated more than 5000+ Students all over India. Including IITs and NITs.
                </p> */}
                <p className="lead text-white">
                  {/* Mobile : +918007340209
                  <br /> */}
                  Email : info@digitalchokra.com
                  <br />
                  Location : Dubai. UAE.
                </p>
                {/* <Button
                  className="btn-white mt-4"
                  color="default"
                  href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                >
                  See all components
                </Button> */}
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">Get in touch?</h4>
                    {/* <p className="mt-0">
                      Your project is very important to us.
                      </p> */}
                    <FormGroup
                      className={classnames("mt-5", {
                        focused: this.state.nameFocused,
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Your name"
                          type="text"
                          value={this.state.name}
                          onFocus={(e) => this.setState({ nameFocused: true })}
                          onBlur={(e) => this.setState({ nameFocused: false })}
                          onChange={(event) =>
                            this.setState({ name: event.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.emailFocused,
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email address"
                          type="email"
                          value={this.state.email}
                          onFocus={(e) => this.setState({ emailFocused: true })}
                          onBlur={(e) => this.setState({ emailFocused: false })}
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        className="form-control-alternative"
                        cols="80"
                        name="name"
                        value={this.state.message}
                        placeholder="Type a message..."
                        rows="4"
                        type="textarea"
                        onChange={(event) =>
                          this.setState({ message: event.target.value })
                        }
                      />
                    </FormGroup>
                    <div>
                      <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="button"
                        onClick={this.submitQuery}
                      >
                        Send Message
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <SimpleFooter />
      </>
    );
  }
}

export default Profile;
